<template>
	<div class="goods-detail">
		
		<div class="in-sousuo">
			<div class="sousuo-box">
				 
				<input type="text"   v-model="keyword" @keyup.enter="search" maxlength="50" />
				<el-button class="serbtn" type="primary" size="small" @click="search">搜索</el-button>
			</div>
			<div class="hot-search-words" v-if="hotSearchWords.length">
				 
				<ul>
					<li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/pregoods', query: { keyword: item, search_type: 'goods',category_id:0,level:1 } })">
						{{ item }}
					</li>
				</ul>
			</div>
		</div>
		
		<div v-if=" first_child_list" class="top_cate_class">
			<ul> 
				<li :class="{active: item.category_id == first_index_active}"  @click="setFiindex(item)" v-for="item in first_child_list">
					<router-link  :to="{ path: '/pregoods', query: { category_id: item.category_id, level: item.level } }">{{item.category_name}}</router-link>
				</li>
			</ul>
		</div>
		
		<div class="preview-wrap">
			<div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
				<video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true" :options="playerOptions"
				 @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
				 @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
				 @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)" @statechanged="playerStateChanged($event)"
				 @ready="playerReadied"></video-player>

				<div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
					<span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
					<span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
				</div>
			</div>
			<!-- , { size: 'big' } -->
			<div class="magnifier-wrap">
				<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
			</div>

			<div class="spec-items">
				<span class="left-btn iconfont icon-arrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
				<span class="right-btn iconfont icon-arrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
				<ul :style="{ left: 16 + thumbPosition + 'px' }">
					<!-- 商品缩率图 -->
					<li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
						<img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
					</li>
				</ul>
			</div>

			<div class="share-collect" v-if="0">
				<div @click="editCollection">
					<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
					<span data-collects="0">关注商品（{{ goodsSkuDetail.collect_num }}）</span>
				</div>
				<div v-if="kefuConfig.system == 0 && kefuConfig.open_pc == 1">
					<i class="iconfont iconzhanghao"></i>
					<span data-collects="0"><a :href="kefuConfig.open_url" target="_blank">联系客服</a></span>
				</div>
				<div @click="service_link" v-else-if="kefuConfig.system == 1">
					<i class="iconfont iconzhanghao"></i>
					<span data-collects="0">联系客服</span>
				</div>
			</div>
		</div>

		<!-- 商品信息 -->
		<div class="basic-info-wrap" v-loading="loading">
			<h1>{{ goodsSkuDetail.sku_name }}</h1>
			<div class="attrbm"  v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index" v-if="item.attr_name=='编码'">{{ item.attr_name }}：{{ item.attr_value_name }}</div>
			<ul class="attr-list">
				<template v-if="goodsSkuDetail.goods_attr_format && goodsSkuDetail.goods_attr_format.length > 0">
					<li v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index" v-if="item.attr_name!='编码'">{{ item.attr_name }}：{{ item.attr_value_name }}
					<span v-if="item.attr_name=='种植基地'&&goodsSkuDetail.linkurl!=''" @click="openjdsk" class="jidishikuang">基地实况</span>
					</li>
				</template>
			</ul>

			<div class="discount-banner ns-bg-color" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime && addonIsExit.discount">
				<div class="activity-name">
					<i class="discount-icon iconfont iconicon_naozhong"></i>
					<span>限时折扣</span>
				</div>
				<div class="surplus-time">
					<span>{{ discountText }}</span>
					<count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
					 :currentTime="discountTimeMachine.currentTime" :startTime="discountTimeMachine.startTime" :endTime="discountTimeMachine.endTime"
					 :dayTxt="'天'" :hourTxt="'小时'" :minutesTxt="'分钟'" :secondsTxt="'秒'"></count-down>

					<!-- :tipText="'距离开始文字1'"
					:tipTextEnd="'距离结束文字1'"
                    :endText="'结束自定义文字2'"-->
				</div>
			</div>

			<div class="item-block">
				<div class="promotion-price">
					<dl class="item-line">
						<dt class="ns-text-color-gray">销售价</dt>
						<dd>
							<em class="yuan ns-text-color">¥</em>
							<span class="price ns-text-color">{{ goodsSkuDetail.discount_price }}</span>
						</dd>
						<dt class="ns-text-color-gray">每手</dt>
						<dd>						
							<span class="price ns-text-color">{{ goodsSkuDetail.weight }}</span>
							<em class="yuan ns-text-color">/KG</em>
						
						</dd>
						<dt class="ns-text-color-gray">总手</dt>
						<dd>						
							<span class="price ns-text-color">{{ goodsSkuDetail.stock }}</span>
							<em class="yuan ns-text-color">{{ goodsSkuDetail.unit }}</em>
						
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime">
						<dt class="ns-text-color-gray">原价</dt>
						<dd>
							<em class="market-yuan">¥</em>
							<span class="market-price">{{ goodsSkuDetail.price }}</span>
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.member_price > 0">
						<dt class="ns-text-color-gray">会员价</dt>
						<dd>
							<em class="market-yuan">¥</em>
							<span class="member_price">{{ goodsSkuDetail.member_price }}</span>
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.market_price > 0">
						<dt class="ns-text-color-gray">市场价</dt>
						<dd>
							<em class="market-yuan">¥</em>
							<span class="market-price">{{ goodsSkuDetail.market_price }}</span>
						</dd>
					</dl>

					<div class="statistical">
						<ul>
							<li>
								<p>累计评价</p>
								<span>{{ goodsSkuDetail.evaluate }}</span>
							</li>
							<li>
								<p>累计销量</p>
								<span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
							</li>
						</ul>
					</div>
					<dl class="item-line coupon-list" v-if="addonIsExit.coupon && couponList.length">
						<dt class="ns-text-color-gray">优惠券</dt>
						<div>
							<dd>
								<p v-for="(item, index) in couponList" :key="index" class="ns-text-color" @click="receiveCoupon(item.coupon_type_id)">
									<span class="ns-border-color" v-if="item.type == 'discount'">{{ item.discount }}折</span>
									<span class="ns-border-color" v-if="item.type == 'reward'">￥{{ item.money }}</span>
									<label v-if="item.type == 'discount'">{{ item.discount }}折</label>
									<label v-if="item.type == 'reward'">￥{{ item.money }}</label>
								</p>
							</dd>
						</div>
					</dl>
					<dl class="item-line manjian" v-if="manjian.manjian" style="align-items: top;">
						<dt>满减</dt>
						<dd>
							<i class="i-activity-flag ns-text-color ns-border-color">{{ manjian.manjian_name }}</i>
							<span>{{ manjian.manjian }}</span>
							<!-- <view class="item" v-if="manjian.manjian != undefined" style="display: flex;">
								<view class="free-tip color-base-text color-base-border">满减</view>
								<text class="font-size-base">{{ manjian.manjian }}</text>
							</view> -->
						</dd>
					</dl>
					<dl class="item-line manjian" v-if="manjian.mansong != undefined">
						<dt>满送</dt>
						<dd style="display: flex;align-items: top;">
							<i class="i-activity-flag ns-text-color ns-border-color" style="height: 14px;line-height: 14px;margin-top: 5px;">{{ manjian.manjian_name }}</i>
							<div>
								{{ manjian.mansong }}
							</div>
						</dd>
					</dl>
					<dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
						<dt>运费</dt>
						<dd>
							<i class="i-activity-flag ns-text-color ns-border-color" v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
							<i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
						</dd>
					</dl>
					<!-- <div class="manjian-box">						
						<dl class="item-line manjian manjian-hide" v-if="addonIsExit.manjian && manjian.manjian_name">
							<dt>满减</dt>						
							<dd>					
								<i class="i-activity-flag ns-text-color ns-border-color">{{ manjian.manjian_name }}</i>						
								<span v-if="manjian.manjian"><i class="i-activity-flag ns-text-color ns-border-color">满减</i></span>
								<span v-if="manjian.mansong"><i class="i-activity-flag ns-text-color ns-border-color">满送</i></span>
								<span v-if="manjian.free_shipping"><i class="i-activity-flag ns-text-color ns-border-color">包邮</i></span>
							</dd>
							<span class="manjian-open">展开促销<i class="el-icon-arrow-down"></i></span>
						</dl>
						<dl class="item-line manjian manjian-show" v-if="addonIsExit.manjian && manjian.manjian_name">
							<dt>满减</dt>						
							<dd>		
								<span v-if="manjian.manjian"><i class="i-activity-flag ns-text-color ns-border-color">满减</i>{{ manjian.manjian }}</span></br>
								<span v-if="manjian.mansong"><i class="i-activity-flag ns-text-color ns-border-color">满送</i>{{ manjian.mansong }}</span></br>
								<span v-if="manjian.free_shipping"><i class="i-activity-flag ns-text-color ns-border-color">包邮</i>{{ manjian.free_shipping }}</span>
							</dd>
						</dl>
					</div> -->
				</div>
			</div>
			<!-- <dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
				<dt>运费</dt>
				<dd>
					<i class="i-activity-flag ns-text-color ns-border-color" v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
					<i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
				</dd>
			</dl> -->
			<dl class="item-line delivery" v-if="goodsSkuDetail.is_virtual == 0">
				<dt>配送至</dt>
				<dd>
					<div class="region-selected ns-border-color-gray">
						<span>
							<template v-if="selectedAddress['level_1']">
								<template v-for="item in selectedAddress">
									{{ item.name }}
								</template>
							</template>
							<template v-else> 请选择配送地址 </template>
						</span>
						<i class="el-icon-arrow-down"></i>
					</div>

					<div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
						<ul class="nav-tabs">
							<li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
								<div>
									<span>{{ selectedAddress["level_1"] ? selectedAddress["level_1"].name : "请选择省" }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
							<li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
								<div>
									<span>{{ selectedAddress["level_2"] ? selectedAddress["level_2"].name : "请选择市" }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
							<li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
								<div>
									<span>{{ selectedAddress["level_3"] ? selectedAddress["level_3"].name : "请选择区/县" }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
								<ul class="province">
									<li v-for="(item, index) in provinceArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('city', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
							<div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
								<ul class="city">
									<li v-for="(item, index) in cityArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('district', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
							<div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
								<ul class="district">
									<li v-for="(item, index) in districtArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('community', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</dd>
			</dl>
			<dl class="item-line service">
				<dt>服务</dt>
				<dd>
					<span>
						由
						<span class="ns-text-color">{{siteInfo.site_name}}</span>
						发货并提供售后服务
					</span>
				</dd>
			</dl>
			<hr class="divider" />
			<div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
				<dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
					<dt>{{ item.spec_name }}</dt>
					<dd>
						<ul>
							<li v-for="(item_value, index_value) in item.value" :key="index_value">
								<div :class="{
                                        'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                        disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
                                    }"
								 @click="changeSpec(item_value.sku_id, item_value.spec_id)">
									<img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
									<span>{{ item_value.spec_value_name }}</span>
									<i class="iconfont iconduigou1 ns-text-color"></i>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>

			<div class="buy-number">
				<dl class="item-line">
					<dt>数量</dt>
					<dd>
						<div class="num-wrap">
							<el-input v-model="number" placeholder="0" @input="keyInput()"></el-input>
							<div class="operation">
								<span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
								<span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
							</div>
						</div>
						<span class="unit">{{ goodsSkuDetail.unit }}</span>
						<span class="inventory">库存{{ goodsSkuDetail.stock }}{{ goodsSkuDetail.unit }}</span>
						<!-- 限购 -->
						<em v-if="goodsSkuDetail.is_limit==1" class="restrictions">
							<span v-if="goodsSkuDetail.limit_type==1">(限购{{goodsSkuDetail.max_buy}}手)</span> 
							<span v-else>(每人限购{{goodsSkuDetail.max_buy}}手)</span> 
						</em>
					</dd>
				</dl>
			</div>

			<dl class="item-line buy-btn">
				<dt></dt>
				<dd v-if="goodsSkuDetail.goods_state == 1">
					<template v-if="goodsSkuDetail.stock == 0">
						<el-button type="info" plain disabled>库存不足</el-button>
					</template>
					
					<template v-else-if="goodsSkuDetail.max_buy != 0 && goodsSkuDetail.purchased_num >= goodsSkuDetail.max_buy">
						<el-button type="info" plain disabled>已达最大限购数量</el-button>
					</template>
					<template v-else>
						<el-button type="primary" icon="el-icon-shopping-cart-2" plain @click="joinCart">加入自选</el-button>
					    <el-button type="primary"   @click="buyNow">立即购买</el-button>
					</template>
					<template v-else>
						<el-button type="info" plain disabled>该商品已下架</el-button>
					</template>

					 
				</dd>

				<dd v-else>
					<template>
						<el-button type="info" plain disabled>该商品已下架</el-button>
					</template>
					<div href="javascript:;" class="go-phone">
						<img src="@/assets/images/goods/qrcode.png" />
						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
					</div>
				</dd>
			</dl>
			<!-- shopInfo.shop_baozh == 1 || shopInfo.shop_qtian == 1 || shopInfo.shop_zhping == 1 || shopInfo.shop_erxiaoshi == 1 || shopInfo.shop_tuihuo == 1 || shopInfo.shop_shiyong == 1 || shopInfo.shop_shiti == 1 || shopInfo.shop_xiaoxie == 1 -->
			<dl class="item-line merchant-service" v-show="service_list.length">
				<dt>商品服务</dt>
				<div>
					<dd v-for="item in service_list">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="item.service_name">{{item.service_name}}</span>
					</dd>
				</div>
			</dl>
		</div>

		<!-- 店铺信息 -->
		<!-- <div class="shop-wrap">
            <div class="head-wrap">
                <div class="img-wrap">
                    <img class="img-responsive center-block" :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)" @error="shopInfo.avatar = defaultShopImage" :alt="shopInfo.site_name" />
                </div>
                <h5>
                    <span class="site-name">{{ shopInfo.site_name }}</span>
                    <el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">自营</el-tag>
                </h5>
            </div>
            <div class="info-wrap">
                <dl>
                    <dt class="site-score">店铺评分</dt>
                    <dd><el-rate v-model="score" disabled></el-rate></dd>
                    <dt>商品描述：</dt>
                    <dd>
                        <span>{{ shopInfo.shop_desccredit }}</span>
                        分
                    </dd>
                    <dt>卖家服务：</dt>
                    <dd>
                        <span>{{ shopInfo.shop_servicecredit }}</span>
                        分
                    </dd>
                    <dt>发货速度：</dt>
                    <dd>
                        <span>{{ shopInfo.shop_deliverycredit }}</span>
                        分
                    </dd>
                </dl>
            </div>
            <div class="info-wrap" v-if="shopInfo.telephone">
                <dl>
                    <dt>联系电话：</dt>
                    <dd>{{ shopInfo.telephone }}</dd>
                </dl>
            </div>
            <div class="operation">
                <el-button class="btn btn-default" size="medium" @click="$router.pushToTab('shop-' + shopInfo.site_id)">进店逛逛</el-button>
                <el-button size="medium" @click="follow" v-if="hasFollow">取消关注</el-button>
                <el-button size="medium" @click="follow" v-else>关注店铺</el-button>
            </div>
        </div> -->

		<!-- 组合套餐 -->
		<el-tabs class="bundling-wrap" v-model="tabBundling" @tab-click="bundlingChange" v-if="addonIsExit.bundling && bundling.length && bundling[0].bl_name">
			<el-tab-pane :label="item.bl_name" :name="'bundling_' + item.bl_id" v-for="(item, index) in bundling" :key="index">
				<div class="master">
					<div class="sku-img"><img :src="$img(goodsSkuDetail.sku_image, { size: 'mid' })" /></div>
					<div class="sku-name">{{ goodsSkuDetail.sku_name }}</div>
					<div class="sku-price ns-text-color">￥{{ goodsSkuDetail.price }}</div>
					<i class="el-icon-plus"></i>
				</div>
				<div class="operation">
					<div class="price-wrap">
						<span>组合套餐价</span>
						<strong class="bl-price ns-text-color">￥{{ item.bl_price }}</strong>
					</div>
					<el-button type="primary" size="medium" @click="$router.pushToTab('promotion/combo-' + item.bl_id)">立即购买</el-button>
					<i class="equal">=</i>
				</div>

				<div class="suits">
					<ul>
						<li v-for="(goods, goods_index) in item.bundling_goods" :key="goods_index" @click="$router.pushToTab({ path: '/sku-' + goods.sku_id })">
							<div class="sku-img"><img :src="$img(goods.sku_image, { size: 'mid' })" /></div>
							<div class="sku-name">{{ goods.sku_name }}</div>
							<div class="sku-price ns-text-color">￥{{ goods.price }}</div>
						</li>
					</ul>
				</div>
			</el-tab-pane>
		</el-tabs>

		<div class="detail-wrap">
			 
			<el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
				<el-tab-pane :label="goodsSkuDetail.syname" name="detail">
					<div  class="ownattrlist">
						<el-tabs  class="ownattrtab" type="card"  @tab-click="attrTabChange">
							<el-tab-pane  :label="item.c_name"   v-for="(item, index) in goodsSkuDetail.sylist" :key="index">
								<div class="attrlist" v-for="(citem, cindex) in item.childcat" :key="cindex">
									<div class="alleft">{{citem.c_name}}</div>
									<div class="alright">
										<ul>
											<li v-for="(aitem, aindex) in citem.attrlist" :key="aindex">
												{{aitem.v_name}} ： {{aitem.v_value}}
												<img :src="$img(imgitem)" @click="openbigimg(imgitem)" v-if="aitem.v_img.length>0" v-for="(imgitem, imgindex) in aitem.v_img" :key="imgindex" />
											</li>
										</ul>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
						 
					</div>
				</el-tab-pane>
				<el-tab-pane v-if="goodsSkuDetail.pjinfo" :label="goodsSkuDetail.pjinfo.g_name" name="attr">
					<div class="pjinfo">
						<div class="pjtitle">
							评级结果：{{goodsSkuDetail.pjinfo.g_result}}
						</div>
						<div class="pjcontent" v-html="goodsSkuDetail.pjinfo.g_detail"></div>
					</div>
				</el-tab-pane>
				<el-tab-pane v-if="goodsSkuDetail.hyname" :label="goodsSkuDetail.hyname" name="evaluate" class="evaluate">
					<template v-if="goodsSkuDetail.hylist.length>0">
					 
						<ul class="list">
							<li v-for="(item, index) in goodsSkuDetail.hylist" :key="index" @click="openhy(item.title,item.content)">
								 {{ item.title }} 
							</li>
						</ul>
						 
					</template>
					<div class="empty" v-else>暂无信息</div>
				</el-tab-pane>
				<template v-if="service">
					<el-tab-pane  v-if="service_is_display.is_display == 1" :label="service.title"  name="after_sale" class="after-sale"><div v-html="service.content"></div></el-tab-pane>
				</template>
			</el-tabs>
		</div>
		
		<!--联系客服弹窗-->
		<servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:shopInfo.site_id,logo:shopInfo.logo,shop_name:shopInfo.site_name}"></servicerMessage>
		
		<el-dialog  title="" :visible.sync="imgVisible" width="60%" :before-close="attrimgClose"
		 :lock-scroll="false" center>
			<div   class="xyContent"><img :src="attrimgsrc"/></div>
		</el-dialog>
		<el-dialog  :title="hytitle" :visible.sync="hyVisible" width="60%" :before-close="hyClose"
		 :lock-scroll="false" center>
			<div  class="hyContent" v-html="hycontent"></div>
		</el-dialog>
		<el-dialog  title="" :visible.sync="jdskVisible" width="30%" :before-close="jdskClose" :lock-scroll="false" center>
			<div class="xyContent">
				 <div class="xcitem">实况地址：{{goodsSkuDetail.linkurl}}</div>
				 <div class="xcitem">登录账号：{{goodsSkuDetail.linkaccount}}</div>
				 <div class="xcitem">登录密码：{{goodsSkuDetail.linkpass}}</div>  
				 <div><el-button type="primary" v-if="goodsSkuDetail.is_virtual == 0" @click="openurl(goodsSkuDetail.linkurl)">打开实况</el-button></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import PicZoom from "vue-piczoom";
	import detail from "./detail";
	import GoodsRecommend from "@/components/GoodsRecommend";
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		name: "detail",
		components: {
			PicZoom,
			GoodsRecommend,
			servicerMessage
		}, 
		mixins: [detail]
	}
</script>
<style lang="scss">
	@import "./detail.scss";
</style>
